body {
  background-color: rgb(242, 243, 246);
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif !important;
}

.bg-light {
  background-color: rgb(242, 243, 246) !important;
}

h2 {
  font-size: 26px;
  font-weight: 300;
  line-height: 1.4;
}

.row-bordered, .row-backbordered {
  position: relative;
}
.row-padded {
  padding-top: 30px;
  padding-bottom: 30px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.footer .row .footer-list-link {
  color: inherit;
  text-align: left;
}

.footer a {
  text-decoration: none;
}

.row-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-l-md {
  padding-left: 22.5px !important;
}
.p-r-md {
  padding-right: 22.5px !important;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.custom-nav-links {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 0px 20px;
  font-size: 15px;
  font-weight: bold;
}

.custom-nav-links>a {
  margin-right: 10px;
}

.custom-nav-links>a:hover {
  color: red !important;
}

.nav-link:active {
  color: red !important;
}

.navbar-brand {
  font-weight: bold;
}

.main-banner {
  width: 80%;
  margin: 50px auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 20px;
  padding-bottom: 20px;
}

.main-statement {
  margin: auto;
  text-transform: uppercase;
}

.industry {
  height: 30px;
  width: 170px;
}

.policy {
  height: 30px;
  width: 55%;
  padding: 20px;
}

.input {
  border-radius: 15px;
  padding: 15px 20px;
  border: 1px solid grey;
  margin-right: 10px;
  width: 100%;
  max-width: 500px;
}

.download-button {
  background: #e52d27;
  background: -webkit-linear-gradient(to right, #b31217, #e52d27);
  background: linear-gradient(to right, #b31217, #e52d27);
  color: white;
  border: transparent;
  border-radius: 10px;
  padding: 16px;
  cursor: pointer;
}

.download-button>.svg {
  color: white;
}

.download-button>.svg:hover {
  color: black;
}

.input-group-container {
  width: 100%;
  margin: auto;
  /* padding-top: 65px; */
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
}

.about-container {
  background-color: #f8f9fa;
  padding: 50px 20px;
  text-align: center;
}

.about-content {
  max-width: 600px;
  margin: 0 auto;
}

.about-button {
  color: white;
  background: #e52d27;
  background: -webkit-linear-gradient(to right, #b31217, #e52d27);
  background: linear-gradient(to right, #b31217, #e52d27);
  border: 2px solid transparent;
  border-radius: 25px;
  padding: 0px 30px 0px 30px;
  margin-top: 20px;
  cursor: pointer;
}

.know-more-button {
  background: #e52d27;
  background: -webkit-linear-gradient(to right, #b31217, #e52d27);
  background: linear-gradient(to right, #b31217, #e52d27);
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.about-content>h1 {
  font-size: 24px;
  margin-top: 20px;
}

.about-content>p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 10px;
}

.gradient {
  --r: 40px;
  --b: 2px;

  background: linear-gradient(to right, #e52d27 0%, #b31217 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;

  border-radius: var(--r);
  display: flex;
  align-items: center;
  justify-content: center;
  font: 0.8rem 'Oswald', Arial, sans-serif;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  text-decoration: none;
}

.gradient::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  border: var(--b) solid transparent;
  border-radius: var(--r);
  background: inherit;
  background-origin: border-box;
  background-clip: border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-repeat: no-repeat;
}


.utility {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding-top: 15px;
}

.utility>.utility-youtube-icon {
  color: red;
}

.utility>.utility-download-icon {
  color: grey;
}

.utility>h1 {
  font-size: 22px;
  font-weight: bold;
  color: #c10841;
}

.utility>p {
  font-size: 13px;
  width: 80%;
  margin: auto;
  text-align: justify;
  margin-bottom: 20px;

}

.footer p:last-of-type, .footer-social-buttons {
  margin-bottom: 20px;
}

.footer p {
  margin-bottom: 5px;
}

.bi-arrow-down-short.up {
  transform: rotate(180deg);
}

.header-icons {
  color: red;
  margin: 0px 2px 4px 0px;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
  color: red !important;
}

.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #FFF;
  box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #FFF;
    box-shadow: -24px 0 #FF3D00, 24px 0 #FFF;
  }

  66% {
    background: #FF3D00;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  }

  100% {
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FF3D00;
  }
}

.active-visibility {
  display: block;
}

.inActive-visibility {
  display: none;
}

@media (max-width: 768px) {
  .utility {
    margin: 0 auto;
  }

  .policy {
    height: 30px;
    width: 80%;
    font-size: 0.4rem !important;
    padding: 10px 5px 10px 5px;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .main-banner {
    width: 100%;
    padding: 0px;
    border-radius: 0;
    box-shadow: none;
    background-color: rgb(242, 243, 246);
  }
}

@media (max-width: 768px) {
  .input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .input-group-container {
    flex-direction: column;
    align-items: center;
  }
}

#search-form {
  margin: 36px auto;
  max-width: 600px;
  position: relative;
}

#search-form #txt-url {
  padding-right: 60px;
  width: 100%;
  height: 60px;
  border: 5px solid #ff0068;
  border-radius: 4px;
}

.btn-lg.focus, .btn-lg:focus, .btn-lg {
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #ff0068;
  background: #f20a51;
  font-weight: 400;
}

#search-form #btn-submit {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  width: 60px;
  border-radius: 0 4px 4px 0;
}
@media (min-width: 768px)
  {
    #search-form #btn-submit {
      position: absolute;
      right: 0;
      top: 0;
      height: 60px;
      width: 120px;
      border-radius: 0 4px 4px 0;
  }
}

@media (max-width: 767px)
{.hidden-xs {
    display: none !important;
}}

.featured h3, a.active {
  color: #c10841;
}
body h4 a, body p a {
  text-decoration: none;
  color: #6b15a1;
}

a:hover, a:focus, p a {
  text-decoration: none;
  color: #c10841;
}
a {
  text-decoration: none;
  color: inherit;
}

#search-form p {
  color: #666;
  font-size: 13px;
  padding: 6px;
}
small, .small {
  font-size: 87%;
}

.main-container{
  text-align: justify;
}

#logo-name {
  display: inline-block;
  font-size: 23px;
  font-weight: 700;
  line-height: 46px;
  padding-left: 10px;
}