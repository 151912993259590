.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 1;
    width: 90%;
    max-width: 500px;
    height: inherit;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: black;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 20px;
}

.note-container {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.note-icon {
    font-size: 24px;
    margin-right: 10px;
}

.note-message {
    color: #333;
    font-size: 16px;
}

@media (max-width: 768px) {
    .modal-content {
        width: 90%;
        max-width: 90%;
    }
}

@media (max-width: 480px) {
    .modal-content {
        width: 90%;
        max-width: 90%;
    }
}